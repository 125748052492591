import React from 'react';
import {
    Typography,
    makeStyles, Container
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ThemConfig } from '../../types/Them';

const useStyles = makeStyles((theme: ThemConfig) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(3),
        paddingTop: 80,
        paddingBottom: 80
    },
    text: {
        fontWeight: 'bold'
    }
}));

function NotImplemented() {
    const classes = useStyles();

    return (
        <Container
            maxWidth="lg"
            className={classes.root}
        >
            <Typography
                align="center"
                variant="h1"
                color="textPrimary"
                className={classes.text}
            >
                <FormattedMessage id='app:Global;NotImplemented'
                                  defaultMessage='Not implemented yet'/>
            </Typography>
        </Container>
    );
}

export default NotImplemented;
