import React, {
  useRef,
  useState,
  useEffect
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  useDispatch,
} from 'react-redux';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles, colors
} from '@material-ui/core';
import {
  Bell as BellIcon,
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon
} from 'react-feather';
import { useSnackbar } from 'notistack';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { API, CLEAN_API } from '../../../constants/constants';
import axios from '../../../utils/axios';
import notificationService from '../../../services/notificationService';
import {getUserRole} from "../../../utils/helper";

const iconsMap: any = {
  order_placed: PackageIcon,
  new_message: MessageIcon,
  item_shipped: TruckIcon
};

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 430
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  listItem: {},
  list: {}
}));

function Notifications() {
  const classes = useStyles();
  const [notifications, setNotifications] = useState<any[]>([])
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [ connection, setConnection ] = useState<any>(null);
  const [ chat, setChat ] = useState<any>([]);
  const latestChat = useRef<any>(null);
  const history = useHistory();
  const isAdmin = getUserRole() === 'Administrator'

  latestChat.current = chat;

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${CLEAN_API}/chatHub`)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection) {
      connection.start()
        .then((result: any) => {
          console.log('Connected!');

          connection.on('ReceiveMessage', async (message: any) => {

            enqueueSnackbar(message.title, {
              variant: 'info',
              autoHideDuration: 4000
            });
          });
        })
        .catch((e: any) => console.log('Connection failed: ', e));
    }
  }, [connection]);

  const getNotifications = async () => {

    fetch(`${API}/Message/Admin`, { method: 'GET', headers:
        {
          'Authorization': axios.defaults.headers.common.Authorization,
          'Accept': 'application/json, text/plain, */*',
          'Connection': 'keep-alive'
        }
    })
      .then((response: any) => {
        return response.json()
      })
      .then(async (data) => {
        setNotifications(data)
      })
      .catch((err) => {
      console.log(err);
    });
  }

  useEffect( () => {
    if (isOpen) {
      getNotifications()
    }
  },[isOpen])

  const sendMessage = async (user: any, message: any) => {
    const chatMessage = {
      user: user,
      message: message
    };

    if (connection.connectionStarted) {
      try {
        await connection.send('SendMessage', chatMessage);
      }
      catch(e) {
        console.log(e);
      }
    }
    else {
      alert('No connection to server yet.');
    }
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if(!isAdmin) return null;

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          color="inherit"
          ref={ref}
          onClick={handleOpen}
        >
          <SvgIcon>
            <BellIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2} style={{backgroundColor: 'white', zIndex: 1000, position: 'sticky', top: 0}}>
          <Typography
            variant="h5"
            color="textPrimary"
          >
            Нотифікації
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box p={2}>
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Немає повідомлень
            </Typography>
          </Box>
        ) : (
          <>
            <List
              className={classes.list}
              disablePadding
            >
              {notifications.map((notification:any) => {
                const Icon = iconsMap.new_message;

                return (
                  <ListItem
                    className={classes.listItem}
                    component={Button}
                    divider
                    key={notification.id}
                    style={{backgroundColor: notification.isRead ? '' : colors.blue[50]}}
                    // to="#"
                    onClick={() => {
                      if (!notification.isRead) {
                        notificationService.readMessage(notification.id)
                      }
                      history.push(`/app/order/view/${notification.payload.orderId}`)
                      setOpen(false)
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <Icon />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification.title}
                      primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                      secondary={`${notification.content.slice(0, 60)}...`}
                    />
                  </ListItem>
                );
              })}
            </List>
            {/*<Box
              p={1}
              display="flex"
              justifyContent="center"
            >
              <Button
                component={RouterLink}
                size="small"
                to="#"
              >
                Відмітити всі як прочитані
              </Button>
            </Box>*/}
          </>
        )}
      </Popover>
    </>
  );
}

export default Notifications;
