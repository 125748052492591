import React, {
} from 'react';
import { hasUserAccess } from '../utils/helper';
import { Route } from 'react-router';
import NotImplemented from '../views/pages/NotImplemented';

export interface AccessRouteProps {
    path: string | string[];
    component: any;
    exact?: boolean;
    roles: string[];
}

function AccessRoute({
                   path,
                   component,
                   exact,
                   roles,
               }: AccessRouteProps) {
    exact = false;

    return (
        <Route
            path={ path }
            component={ hasUserAccess(roles) ? component : NotImplemented}
        />
    );
}

export default AccessRoute;
