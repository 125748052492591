import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import User from "../types/User";

/*interface AuthRouteProps {
    component?: any,
    render?: any,
    exact?: any,
    path?: any,
}*/

interface State {
    account: {
        user: User,
    },
}

function AuthRoute({ component: Component, render, ...rest }: any) {
    const account = useSelector((state: State) => state.account);

    if (!account.user) {
        return <Redirect to="/login"/>;
    }
    if (account.user && !account.user.isVerified) {
        return <Redirect to="/verification"/>;
    }

    return render ? render({ ...rest }) : <Component { ...rest } />;
}

export default AuthRoute;
