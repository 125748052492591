import React, { useState, useRef } from 'react';
import { capitalCase } from 'change-case';
import {
  Badge,
  Box,
  Button,
  IconButton,
  Popover,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from '../../../hooks/useSettings';
import { LANGS, THEMES } from '../../../constants/enums';
import { changeLanguage } from '../../../utils/i18nHelpers';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 320,
    padding: theme.spacing(2)
  }
}));

function Settings() {
  const classes = useStyles();
  const ref = useRef(null);
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
    language: settings.language,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
//TODO: remove any
  const handleChange = (field: string, value: any) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = () => {
    saveSettings && saveSettings(values);
    changeLanguage(values.language);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Settings">
        <Badge
          color="secondary"
          variant="dot"
          classes={{ badge: classes.badge }}
        >
          <IconButton
            color="inherit"
            onClick={handleOpen}
            ref={ref}
          >
            <SvgIcon fontSize="small">
              <SettingsIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography
          variant="h4"
          color="textPrimary"
        >
          <FormattedMessage id="app:TopBar;Settings;title"
                            defaultMessage="Settings"/>
        </Typography>
        {/*<Box
          mt={2}
          px={1}
        >
          <FormControlLabel
            control={(
              <Switch
                checked={values.direction === 'rtl'}
                edge="start"
                name="direction"
                onChange={(event) => handleChange('direction', event.target.checked ? 'rtl' : 'ltr')}
              />
            )}
            label="RTL"
          />
        </Box>*/}
        {/*<Box
          mt={2}
          px={1}
        >
          <FormControlLabel
            control={(
              <Switch
                checked={values.responsiveFontSizes}
                edge="start"
                name="direction"
                onChange={(event) => handleChange('responsiveFontSizes', event.target.checked)}
              />
            )}
            label="Responsive font sizes"
          />
        </Box>*/}
        <Box mt={2}>
          <TextField
            fullWidth
            label={<FormattedMessage id="app:TopBar;Settings;Theme;title"
                                     defaultMessage="Theme"/>}
            name="theme"
            onChange={(event) => handleChange('theme', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map((theme) => (
              <option
                key={theme}
                value={theme}
              >
                {capitalCase(theme)}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <TextField
              fullWidth
              label={<FormattedMessage id="app:TopBar;Settings;Language;title"
                                       defaultMessage="Language"/>}
              name="language"
              onChange={(event) => handleChange('language', event.target.value)}
              select
              SelectProps={{ native: true }}
              value={values.language}
              variant="outlined"
          >
            {Object.values(LANGS).map((theme) => (
                <option
                    key={theme}
                    value={theme}
                >
                  {capitalCase(theme)}
                </option>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSave}
          >
            <FormattedMessage id="app:TopBar;Settings;button;title"
                              defaultMessage="Save Settings"/>
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default Settings;
