import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import { TenderList } from '../types/TenderList';

export function generatePassword() {
  const length = 8;
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export function removeMask(string: string, symbolsToRemove: string[] = ['+', '(', ')', ' ', '-', ' ', '-', '_']) {
  let res = string;
  symbolsToRemove.map((symbol) => {
    res = res.replace(symbol, '');
  });
  return res;
}

export function allowOnlyNumbers(value: string): boolean {
  const re = /^\d*\.?\d*$/;
  return value === '' || re.test(value);
}

export async function getImageFormData(file: Blob | string): Promise<FormData> {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();
    if (typeof file === 'string') {
      await getFile(file).then((data: Blob) => {
        formData.append('photo', data);
        resolve(formData);
      });
    } else {
      formData.append('photo', file);
      resolve(formData);
    }
  });
}

export function getUserRoles() {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    return jwtDecode(accessToken);
  }
  return {};
}

export function hasUserAccess(acceptRoles: string[]) {

  let rolesData: any = getUserRoles();
  if (!acceptRoles || !acceptRoles.length) {
    return true;
  }
  return _.some(acceptRoles, (item: string) => {
    return Boolean(rolesData[item]);
  });
}

export function getUserRole() {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    let token: any = jwtDecode(accessToken);
    return token.role;
  }
  return {};
}

export async function getFile(url: string): Promise<Blob> {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.onload = function() {
      resolve(xhr.response);
    };
    xhr.send();
  });
}

export function downloadFile(data: string, name: string, type: string) {
  const url = window.URL.createObjectURL(new Blob([data], { type }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
}

export function base64ToFile(data: any, fileName: string, type?: string) {
  let arr = [];
  let mime = '';
  let bstr = '';
  if (type) {
    mime = type;
    bstr = atob(data);
  } else {
    arr = data.split(',');
    mime = arr && arr[0].match(/:(.*?);/)[1];
    bstr = atob(arr[1]);
  }

  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const file = new File([u8arr], fileName, { type: mime });
  downloadSelfUploadedFile(file, fileName);
}

export function base64ToString(str: string) {
  return decodeURIComponent(atob(str).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

export function downloadSelfUploadedFile(data: any, name: string) {
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
}

export function toBase64(file: any): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        const b64 = reader.result && reader.result.replace(/^data:.+;base64,/, '');
        resolve(b64);
      }
    };
    reader.onerror = error => reject(error);
  });
}

export function downloadCsv(data: string, name: string) {
  const hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
  hiddenElement.target = '_blank';
  hiddenElement.download = `${name}.csv`;
  hiddenElement.click();
}

export function normalizeData(data: any): any {
  let returnData: any = {};
  Object.keys(data).map((item) => {
    const number = Number(data[item]);
    if (!isNaN(number)) {
      return returnData[item] = number;
    }
    return returnData[item] = data[item];
  });
  return returnData;
}

export function translit(str: string): string {
  const space = '_';
  let objectKey = '';
  const transl: any = {
    'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'e', 'ж': 'zh',
    'з': 'z', 'и': 'i', 'й': 'j', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
    'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'h',
    'ц': 'c', 'ч': 'ch', 'ш': 'sh', 'щ': 'sh', 'ъ': space,
    'ы': 'y', 'ь': space, 'э': 'e', 'ю': 'yu', 'я': 'ya'
  };
  if (str != '') {
    str = str.toLowerCase();
  }

  for (let i = 0; i < str.length; i++) {
    if (/[а-яё]/.test(str.charAt(i))) {
      objectKey += transl[str.charAt(i)];
    } else if (/[a-z0-9]/.test(str.charAt(i))) {
      objectKey += str.charAt(i);
    } else {
      if (objectKey.slice(-1) !== space) {
        objectKey += space;
      }
    }
  }
  return objectKey;
}

export function currencyCheck(currency: string) {
  switch (currency) {
    case 'USD':
      return '$';
    case 'KZT':
      return '₸';
    case 'RUB':
      return '₽';
    case 'EUR':
      return '€';
    default:
      return '';
  }
}

export function daysTyping(value: any) {

  const string = value.toString();
  const lastChar = string.charAt(string.length - 1);

  if (lastChar == '1' && !(value == 11)) {
    return 'день';
  } else if (lastChar == '2' && !(value == 12)) {
    return 'дня';
  } else if (lastChar == '3' && !(value == 13)) {
    return 'дня';
  } else if (lastChar == '4' && !(value == 14)) {
    return 'дня';
  } else {
    return 'дней';
  }
}

export function hoursTyping(value: any) {

  const string = value.toString();
  const lastChar = string.charAt(string.length - 1);

  if (lastChar == '1' && !(value == 11)) {
    return 'час';
  } else if (lastChar == '2' && !(value == 12)) {
    return 'часа';
  } else if (lastChar == '3' && !(value == 13)) {
    return 'часа';
  } else if (lastChar == '4' && !(value == 14)) {
    return 'часа';
  } else {
    return 'часов';
  }
}

export function minutesTyping(value: any) {

  const string = value.toString();
  const lastChar = string.charAt(string.length - 1);

  if (lastChar == '1' && !(value == 11)) {
    return 'минута';
  } else if (lastChar == '2' && !(value == 12)) {
    return 'минуты';
  } else if (lastChar == '3' && !(value == 13)) {
    return 'минуты';
  } else if (lastChar == '4' && !(value == 14)) {
    return 'минуты';
  } else {
    return 'минут';
  }
}

export function getTitle(path: any) {

  switch (path) {
    case '/procurement':
      return 'Житлово-Експлуатаційне Об\'єднання';
    case '/activeTenders':
      return 'Тендеры в работе';
    case '/favorite':
      return 'Избранные тендеры';
    default:
      return '';
  }
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: any,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const stableSort = (array: any[], comparator: (a: any, b: any) => number) => {
  const stabilizedThis = array.map((el, index) => [el, index] as [TenderList, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export function applySort(employees: any, sort: string): any[] {
  const [orderBy, order] = sort.split('_');
  return _.orderBy(employees, [orderBy], order as 'asc' | 'desc');
}

export function mySort(arr: any[], orderBy: string): any[] {
  return arr.sort(function sortComparer(a, b) {
    return a[orderBy].trim().localeCompare(b[orderBy].trim());
  });
}

export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "win";
}
