import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Auth from './components/Auth';
// import CookiesNotification from './components/CookiesNotification';
// import SettingsNotification from './components/SettingsNotification';
import GoogleAnalytics from './components/GoogleAnalytics';
import ScrollReset from './components/ScrollReset';
import useSettings from './hooks/useSettings';
import { createTheme } from './theme';
import Routes from './Routes';
import { IntlProvider } from 'react-intl-redux';
import { restoreLocale } from './utils/i18nHelpers';
import Loader from './components/Loader';
import OneSignal from 'react-onesignal';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));

function App() {
  useStyles();

  const { settings } = useSettings();

  useEffect(() => {
    OneSignal.init({
      safari_web_id: "web.dev.hma-admin.tk",
      appId: "86038b67-901b-49a0-8241-acc670ca363b" //dev
      // appId: "06e8bfae-e2f8-4c77-94e1-7703db7cb9a9"  //local
    });
    OneSignal.sendTag('tag', 'admin').then(()=>{});
  }, []);


  return (
      <IntlProvider locale={restoreLocale(settings)}>
        <ThemeProvider theme={createTheme(settings)}>
          <StylesProvider jss={jss}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider maxSnack={3}>
                <Router history={ history }>
                  <ScrollReset/>
                  {/*<GoogleAnalytics/>*/}
                  {/*<CookiesNotification />*/ }
                  {/*<SettingsNotification />*/ }
                  <Loader/>
                  <Routes/>
                </Router>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </ThemeProvider>
      </IntlProvider>
  );
}

export default App;
