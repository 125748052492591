export enum THEMES {
  LIGHT = 'LIGHT',
  ONE_DARK = 'ONE_DARK',
  UNICORN = 'UNICORN',
}

export enum DIRECTION {
  ltr = 'ltr',
  rtl = 'rtl',
}

export enum LANGS {
  // EN = 'en',
  RU = 'ru',
  // KZ = 'kz',
  UA = 'ua',
}

export enum SupplierAllowStatus {
  ALLOWED = 'ALLOWED',
  PRICE_TOO_HIGH = 'PRICE_TOO_HIGH',
  NOT_ALLOWED = 'NOT_ALLOWED',
}

export enum DocumentsType {
  OWN = 'Own',
  REQUESTED = 'Requested'
}

export enum TenderFilterObjectType {
  Number = 'n',
  Date = 'd'
}

export enum TenderStatus {
  Created = 'Created',
  Editing = 'Editing',
  Blocked = 'Blocked',
  Deleted = 'Deleted',
  OnApproval = 'OnApproval',
  Published = 'Published',
  Completed = 'Completed',
  Archived = 'Archived',
  SubmissionCompleted = 'SubmissionCompleted',
}

export enum AvailableRoles {
  USER_CREATE = 'User.Create',
  USER_LIST = 'User.List',
  USER_EDIT = 'User.Edit',
  USER_DELETE = 'User.Delete',
  ROLE_CREATE = 'Role.Create',
  ROLE_LIST = 'Role.List',
  ROLE_EDIT = 'Role.Edit',
  ROLE_DELETE = 'Role.Delete',
  REPORT_CREATE = 'Report.Create',
  REPORT_LIST = 'Report.List',
  SAPDATA_VIEW = 'SAPData.View',
  SAPDATA_LIST = 'SAPData.List',
  ORDER_CREATE = 'Order.Create',
  ORDER_LIST = 'Order.List',
  ORDER_EDIT = 'Order.Edit',
  ORDER_COMPLETE = 'Order.Complete',
  CATEGORY_LIST = 'Category.List',
  SERVICE_LIST = 'Service.List',
  SERVICE_EDIT = 'Service.Edit',
  TEAM_LIST = 'Team.List',
  TEAM_CREATE = 'Team.Create',
  TEAM_EDIT = 'Team.Edit',
  MAP_LIST = 'Map.List',
  CALENDAR_LIST = 'Calendar.List',
  QUESTIONNAIRE_LIST = 'Questionnaire.List',
  QUESTIONNAIRE_CREATE = 'Questionnaire.Create',
  CUSTOMER_INFORMING_LIST = 'CustomerInforming.List',
  CUSTOMER_INFORMING_CREATE = 'CustomerInforming.Create',
  REPORT_MASTER_VIEW = 'ReportMaster.List',
  REPORT_ADDRESS_REGISTRATION_VIEW = 'ReportAddressRegistration.List',
  REPORT_MASTER_WORKING_DAY_VIEW = 'ReportMasterWorkingDay.List',
  REPORT_MASTER_TIME_CONTROL_VIEW = 'ReportMasterTimeControl.List',
  REPORT_EVALUATION_GENERAL_VIEW = 'ReportEvaluationGeneral.List',
  REPORT_EVALUATION_MASTER_VIEW = 'ReportEvaluationMaster.List',
  REPORT_EVALUATION_USER_VIEW = 'ReportEvaluationUser.List',
  PAYMENTS = 'Payments.List',
}

export enum SupplierApproveStatus {
  NotApproved = 'NotApproved',
  PartialApproved = 'PartialApproved',
  ApprovedByYou = 'ApprovedByYou',
  FullApproved = 'FullApproved',
}

export enum Roles {
  CUSTOMER = 'Customer',
  EMPLOYEE = 'Employee'
}

export enum QuestionnaireStatus {
  None = 'None',
  NotStarted = 'NotStarted',
  Active = 'Active',
  Completed = 'Completed',
  Deleted = 'Deleted'
}
