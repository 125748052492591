import React from 'react';
import { Box, LinearProgress, makeStyles } from '@material-ui/core';
import { ThemConfig } from '../types/Them';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: ThemConfig) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  absolute: {
    top:0,
    position: 'fixed',
    left: 0,
    right: 0,
    backgroundColor: '#00000069',
    zIndex: 9999
  }
}));

export interface UtilsProps {
  utils: {
    showLoader: boolean;
  };
};

function Loader() {
  const classes = useStyles();
  const { showLoader } = useSelector((state: UtilsProps) => state.utils);
  if (!showLoader) {
    return null;
  }
  return (
      <div className={ `${ classes.root } ${ classes.absolute }` }>
        <Box width={ 400 }>
          <LinearProgress/>
        </Box>
      </div>
  );
}

export default Loader;
