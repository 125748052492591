/* eslint-disable import/prefer-default-export */
import { applyMiddleware, createStore, compose, StoreEnhancer } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
//import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
//import { ENABLE_REDUX_LOGGER } from '../config';
import { LANGS } from '../constants/enums';
import { getMessages } from '../utils/i18nHelpers';
//const loggerMiddleware = createLogger();

const initialState = {
  intl: {
    locale: LANGS.UA,
    messages: getMessages(LANGS.UA),
  },
};

export function configureStore(preloadedState = {}) {
  const middlewares = [thunkMiddleware];

  /*if (ENABLE_REDUX_LOGGER) {
    middlewares.push(loggerMiddleware);
  }*/

  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers) as StoreEnhancer;

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}

const store = configureStore(initialState);

export {
  store,
};
