import React, {
  createContext,
  useState,
} from 'react';
import _ from 'lodash';
import { LANGS, THEMES } from '../constants/enums';
import { storeSettings } from '../utils/settings';
import { Settings } from '../types/Settings';

const defaultSettings: Settings = {
    responsiveFontSizes: true,
    theme: THEMES.LIGHT,
    settings: {},
    language: LANGS.UA,
};

const SettingsContext = createContext(defaultSettings);

export function SettingsProvider(
  {
    settings,
    children,
  }: { settings: Settings, children: React.ReactNode }) {
  const [currentSettings, setCurrentSettings] = useState(settings || defaultSettings);

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

//export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
