import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';
import GuestRoute from './components/GuestRoute';
import AuthRoute from './components/AuthRoute';
import DashboardLayout from './layouts/DashboardLayout';
import Error404View from './views/pages/Error404View';
import NotImplemented from './views/pages/NotImplemented';
import Auth from './components/Auth';
import AccessRoute from './components/AccessRoute';
import { AvailableRoles } from './constants/enums';
import GetAppView from './views/pages/GetAppView';

function Routes() {
    return (
        <Auth>
            <Suspense fallback={ <LoadingScreen/> }>
                <Switch>
                    <Redirect
                        exact
                        from="/"
                        to="/login"
                    />
                    <GuestRoute
                        path="/login"
                        component={ lazy(() => import('./views/auth/LoginView')) }
                    />
                    <Route
                        path="/verification"
                        component={ lazy(() => import('./views/auth/VerificationView')) }
                    />
                    <Route
                        path="/ga"
                        component={ GetAppView }/>

                    <AuthRoute
                        path="/app"
                        render={ (props: any) => (
                            <DashboardLayout { ...props }>
                                {/*<Suspense fallback={<LoadingScreen />}>*/ }
                                <Switch>
                                    <Redirect
                                        exact
                                        from="/app"
                                        to="/app/reports/dashboard"
                                    />
                                    <Route
                                        path="/app/reports/dashboard"
                                        component={ lazy(() => import('./views/reports/DashboardView')) }
                                        // component={ NotImplemented }
                                    />
                                    <AccessRoute
                                        path="/app/management/administrators/list"
                                        roles={ [AvailableRoles.USER_LIST] }
                                        component={ lazy(() => import('./views/management/AdministratorListView')) }
                                    />
                                    <AccessRoute
                                        path="/app/management/teams/list"
                                        roles={ [AvailableRoles.TEAM_LIST] }
                                        component={ lazy(() => import('./views/management/Teams/List')) }
                                    />
                                    <Route
                                        path="/app/management/promotions/list"
                                        component={ lazy(() => import('./views/management/Promotions/List')) }
                                    />
                                    <Route
                                        path="/app/management/promotions/new"
                                        component={ lazy(() => import('./views/management/Promotions/CreateEdit')) }
                                    />
                                    <Route
                                        path="/app/management/promotions/:id"
                                        component={ lazy(() => import('./views/management/Promotions/CreateEdit')) }
                                    />
                                    <AccessRoute
                                        path="/app/management/teams/new"
                                        roles={ [AvailableRoles.TEAM_CREATE] }
                                        component={ lazy(() => import('./views/management/Teams/CreateEdit')) }
                                    />
                                    <AccessRoute
                                        path="/app/management/teams/:id"
                                        roles={ [AvailableRoles.TEAM_EDIT] }
                                        component={ lazy(() => import('./views/management/Teams/CreateEdit')) }
                                    />
                                    <AccessRoute
                                        path="/app/management/customers/list"
                                        roles={ [AvailableRoles.USER_LIST] }
                                        component={ lazy(() => import('./views/management/CustomerListView')) }
                                    />
                                    <AccessRoute
                                        path="/app/users/customers/:userId"
                                        roles={ [AvailableRoles.USER_EDIT] }
                                        component={ lazy(() => import('./views/users/customers/CustomerView')) }
                                    />
                                    <AccessRoute
                                        path="/app/management/employees/list"
                                        roles={ [AvailableRoles.USER_LIST] }
                                        component={ lazy(() => import('./views/management/EmployeeListView')) }
                                    />
                                    <AccessRoute
                                        path="/app/management/user/list"
                                        roles={ [AvailableRoles.USER_LIST] }
                                        component={ lazy(() => import('./views/management/UserListView')) }
                                    />
                                    <AccessRoute path={ "/app/management/users/new" }
                                                 roles={ [AvailableRoles.USER_CREATE] }
                                                 component={ lazy(() => import('./views/management/UserCreateEditView')) }/>
                                    <AccessRoute
                                        path="/app/management/users/:userId/edit"
                                        roles={ [AvailableRoles.USER_EDIT] }
                                        component={ lazy(() => import('./views/management/UserCreateEditView')) }
                                    />
                                    <AccessRoute
                                        path="/app/users/employees/:userId/calendar"
                                        roles={ [AvailableRoles.USER_EDIT] }
                                        component={ lazy(() => import('./views/calendar/CalendarView')) }
                                    />

                                    {/*<AccessRoute
                                        path="/app/users/employees/:userId"
                                        roles={ [AvailableRoles.USER_EDIT] }
                                        component={ lazy(() => import('./views/users/employees/EmployeeView')) }
                                    />*/}
                                    <Route
                                        path="/app/account"
                                        component={ lazy(() => import('./views/pages/AccountView')) }
                                    />
                                    <AccessRoute
                                        path="/app/management/roles/list"
                                        roles={ [AvailableRoles.ROLE_LIST] }
                                        component={ lazy(() => import('./views/management/RoleListView')) }
                                    />
                                    <AccessRoute
                                        path="/app/management/roles/new"
                                        roles={ [AvailableRoles.ROLE_CREATE] }
                                        component={ lazy(() => import('./views/management/RoleCreateEditView')) }
                                    />
                                    <AccessRoute
                                        path="/app/management/roles/:roleId/edit"
                                        roles={ [AvailableRoles.ROLE_EDIT] }
                                        component={ lazy(() => import('./views/management/RoleCreateEditView')) }
                                    />
                                    <AccessRoute
                                        path="/app/management/access"
                                        roles={ [AvailableRoles.ROLE_LIST] }
                                        component={ lazy(() => import('./views/management/AccessView')) }
                                    />
                                    <AccessRoute
                                        path="/app/directories/:type"
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/GenericDirectoryListView')) }
                                    />
                                    <AccessRoute
                                        path="/app/unused/directories/departments/list"
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/DepartmentListView')) }
                                    />
                                    <AccessRoute
                                        path="/app/unused/directories/departments/:depId/:edit"
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/DepartmentCreateEditView')) }
                                    />
                                    <AccessRoute
                                        path="/app/unused/directories/departments/:depId"
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/DepartmentCreateEditView')) }
                                    />
                                    <AccessRoute
                                        path="/app/unused/directories/departments/new"
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/DepartmentCreateEditView')) }
                                    />
                                    <AccessRoute
                                        path="/app/unused/directories/currencies/list"
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/CurrencyListView')) }
                                    />
                                    <AccessRoute
                                        path={ ["/app/unused/directories/currencies/new", "/app/unused/directories/currencies/:currId/:edit", "/app/unused/directories/currencies/:currId"] }
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/CurrencyCreateEditView')) }
                                    />
                                    <AccessRoute
                                        path="/app/unused/directories/exchangeRates/list"
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/ExchangeRatesListView')) }
                                    />
                                    <AccessRoute
                                        path={ ["/app/unused/directories/exchangeRates/new", "/app/unused/directories/exchangeRates/:rateId/:edit", "/app/unused/directories/exchangeRates/:rateId"] }
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/ExchangeRatesCreateEditView')) }
                                    />
                                    <AccessRoute
                                        path="/app/unused/directories/equipmentTypes/list"
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/EquipmentTypesListView')) }
                                    />
                                    <AccessRoute
                                        path={ ["/app/unused/directories/equipmentTypes/new", "/app/unused/directories/equipmentTypes/:id/:edit", "/app/unused/directories/equipmentTypes/:id"] }
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/EquipmentTypesCreateEditView')) }
                                    />
                                    <AccessRoute
                                        path="/app/unused/directories/equipments/list"
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/EquipmentListView')) }
                                    />
                                    <AccessRoute
                                        path={ ["/app/unused/directories/equipments/new", "/app/unused/directories/equipments/:id/:edit", "/app/unused/directories/equipments/:id"] }
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/EquipmentCreateEditView')) }
                                    />
                                    <AccessRoute
                                        path="/app/unused/directories/units/list"
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/UnitsListView')) }
                                    />
                                    <AccessRoute
                                        path={ ["/app/unused/directories/units/new", "/app/unused/directories/units/:id/:edit", "/app/unused/directories/units/:id"] }
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/UnitsCreateEditView')) }
                                    />
                                    <AccessRoute
                                        path="/app/unused/directories/productTypes/list"
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/ProductTypesListView')) }
                                    />
                                    <AccessRoute
                                        path={ ["/app/unused/directories/productTypes/new", "/app/unused/directories/productTypes/:id/:edit", "/app/unused/directories/productTypes/:id"] }
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/ProductTypesCreateEditView')) }
                                    />
                                    <AccessRoute
                                        path="/app/unused/directories/products/list"
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/ProductsListView')) }
                                    />
                                    <AccessRoute
                                        path={ ["/app/unused/directories/products/new", "/app/unused/directories/products/:id/:edit", "/app/unused/directories/products/:id"] }
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/directories/ProductsCreateEditView')) }
                                    />
                                    <Route
                                        path="/app/management/suppliers/list"
                                        component={ lazy(() => import('./views/management/SupplierListView')) }
                                    />
                                    <Route
                                        path={ ["/app/management/suppliers/checks"] }
                                        component={ lazy(() => import('./views/management/SupplierChecksView')) }
                                    />
                                    <Route
                                        path={ ["/app/management/suppliers/check/:id"] }
                                        component={ lazy(() => import('./views/management/SupplierDetailsView')) }
                                    />
                                    <AccessRoute
                                        path="/app/procurement/materials"
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/procurement/MaterialListView')) }
                                    />
                                    <AccessRoute
                                        path="/app/procurement/material/:id"
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/procurement/MaterialView')) }
                                    />
                                    <AccessRoute
                                        path="/app/procurement/services"
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/procurement/ServicesListView')) }
                                    />
                                    <AccessRoute
                                        path="/app/procurement/service/:id"
                                        roles={ [AvailableRoles.SAPDATA_LIST] }
                                        component={ lazy(() => import('./views/procurement/ServicesView')) }
                                    />
                                    <AccessRoute
                                      path="/app/categories1c/list"
                                      roles={ [AvailableRoles.CATEGORY_LIST] }
                                      component={ lazy(() => import('./views/categories/Categories_1c_ListView')) }
                                    />
                                    <AccessRoute
                                        path="/app/categories/list"
                                        roles={ [AvailableRoles.CATEGORY_LIST] }
                                        component={ lazy(() => import('./views/categories/CategoriesListView')) }
                                    />

                                    <Route
                                        path="/app/customer_informing/new"
                                        component={ lazy(() => import('./views/customer-informing/CustomerInfoCreate')) }
                                    />
                                    <Route
                                        path="/app/customer_informing/history"
                                        roles={ [AvailableRoles.CUSTOMER_INFORMING_LIST] }
                                        component={ lazy(() => import('./views/customer-informing/CustomerInfoHistory')) }
                                    />

                                    <AccessRoute
                                        path="/app/categories/new"
                                        roles={ [AvailableRoles.CATEGORY_LIST] }
                                        component={ lazy(() => import('./views/categories/CategoryCreateView')) }
                                    />
                                    <AccessRoute
                                        path="/app/categories/edit/:id"
                                        roles={ [AvailableRoles.CATEGORY_LIST] }
                                        component={ lazy(() => import('./views/categories/CategoryEditView')) }
                                    />
                                    <AccessRoute
                                        path="/app/services/list"
                                        roles={ [AvailableRoles.SERVICE_LIST] }
                                        component={ lazy(() => import('./views/services/ServicesListView')) }
                                    />
                                    <AccessRoute
                                        path="/app/orders/list"
                                        roles={ [AvailableRoles.ORDER_LIST] }
                                        component={ lazy(() => import('./views/orders/OrdersListView')) }
                                    />
                                    <AccessRoute
                                        path="/app/reporting/employees"
                                        roles={ [AvailableRoles.REPORT_MASTER_VIEW] }
                                        component={ lazy(() => import('./views/reporting/ReportingView')) }
                                    />
                                    <AccessRoute
                                        path="/app/reporting/working_day"
                                        roles={ [AvailableRoles.REPORT_MASTER_WORKING_DAY_VIEW] }
                                        component={ lazy(() => import('./views/reporting/WorkingDay')) }
                                    />
                                    <AccessRoute
                                        path="/app/reporting/time_control"
                                        roles={ [AvailableRoles.REPORT_MASTER_TIME_CONTROL_VIEW] }
                                        component={ lazy(() => import('./views/reporting/EmployeeTimeControl')) }
                                    />
                                    <AccessRoute
                                        path="/app/reporting/registrations"
                                        roles={ [AvailableRoles.REPORT_ADDRESS_REGISTRATION_VIEW] }
                                        component={ lazy(() => import('./views/reporting/ReportingByAddress')) }
                                    />
                                    <AccessRoute
                                        path="/app/reporting/work_evaluation"
                                        roles={ [AvailableRoles.REPORT_EVALUATION_GENERAL_VIEW] }
                                        component={ lazy(() => import('./views/reporting/WorkEvaluationReport')) }
                                    />
                                    <AccessRoute
                                        path="/app/reporting/work_evaluation_employee"
                                        roles={ [AvailableRoles.REPORT_EVALUATION_MASTER_VIEW] }
                                        component={ lazy(() => import('./views/reporting/WorkEvaluationEmployee')) }
                                    />
                                    <AccessRoute
                                        path="/app/reporting/work_evaluation_customer"
                                        roles={ [AvailableRoles.REPORT_EVALUATION_USER_VIEW] }
                                        component={ lazy(() => import('./views/reporting/WorkEvaluationCustomer')) }
                                    />
                                    <AccessRoute
                                        path="/app/order/view/:id"
                                        roles={ [AvailableRoles.SERVICE_LIST] }
                                        component={ lazy(() => import('./views/orders/OrderView')) }
                                    />
                                    <AccessRoute
                                        path="/app/services/new"
                                        roles={ [AvailableRoles.SERVICE_LIST] }
                                        component={ lazy(() => import('./views/services/ServicesCreateView')) }
                                    />
                                    <AccessRoute
                                        path="/app/services/edit/:id"
                                        roles={ [AvailableRoles.SERVICE_EDIT] }
                                        component={ lazy(() => import('./views/services/ServicesEditView')) }
                                    />
                                    <Route
                                        path="/app/management/tenders/types/list"
                                        component={ lazy(() => import('./views/management/TenderTypes/TenderTypesListView')) }
                                    />
                                    <AccessRoute
                                      path='/app/questionnaire/list'
                                      roles={[AvailableRoles.QUESTIONNAIRE_LIST]}
                                      component={lazy(() => import('./views/questionnaire/QuestionnaireListView'))}
                                    />
                                    <AccessRoute
                                        path="/app/map/general"
                                        roles={[AvailableRoles.MAP_LIST]}
                                        component={ lazy(() => import('./views/map/general')) }
                                    />
                                    <Route
                                        path="/app/questionnaire/create"
                                        component={ lazy(() => import('./views/questionnaire/CreateEditView')) }
                                    />
                                    <Route
                                        path="/app/questionnaire/edit/:id"
                                        component={ lazy(() => import('./views/questionnaire/CreateEditView')) }
                                    />
                                    <Route
                                      path="/app/questionnaire/results/:id"
                                      component={ lazy(() => import('./views/questionnaire/ResultsView')) }
                                    />
                                    <Route
                                      path="/app/questionnaire/results"
                                      component={ lazy(() => import('./views/questionnaire/QuestionnaireResultsList')) }
                                    />
                                    <Route
                                        path={[
                                            "/app/management/tenders/types/new",
                                            "/app/management/tenders/types/:id/:edit",
                                        ]}
                                        component={ lazy(() => import('./views/management/TenderTypes/TenderTypeCreateView')) }
                                    />
                                    <AccessRoute
                                        path="/app/order/create"
                                        roles={ [AvailableRoles.ORDER_CREATE] }
                                        component={ lazy(() => import('./views/orders/OrderCreateView')) }
                                    />
                                    <AccessRoute
                                        path="/app/order/edit/:id"
                                        roles={ [AvailableRoles.ORDER_CREATE] }
                                        component={ lazy(() => import('./views/orders/OrderCreateView')) }
                                    />
                                    {/*<Route
                                        path="/app/management/tenders/questionnaireTemplates/list"
                                        component={ lazy(() => import('./views/management/TenderQTemplateListView')) }
                                    />
                                    <Route
                                        path={ [
                                            "/app/management/tenders/questionnaireTemplates/new",
                                            "/app/management/tenders/questionnaireTemplates/:id/:edit",
                                            "/app/management/tenders/questionnaireTemplates/:id"] }
                                        component={ lazy(() => import('./views/management/TenderQTemplateCreateEditView')) }
                                    />*/}
                                    {/*<Route
                                        path="/app/management/tenders/faq/list"
                                        component={ lazy(() => import('./views/management/TenderFAQsListView')) }
                                    />
                                    <Route
                                        path={ [
                                            "/app/management/tenders/faq/new",
                                            "/app/management/tenders/faq/:id/:edit",
                                            "/app/management/tenders/faq/:id"] }
                                        component={ lazy(() => import('./views/management/TenderFAQsCreateEditView')) }
                                    />*/}
                                    <Route
                                        path="/app/management/priceDeviation"
                                        component={ lazy(() => import('./views/management/PriceDeviation')) }
                                    />
                                    <Route
                                        path="/app/organizer/mail"
                                        component={ lazy(() => import('./views/mail/MailView')) }
                                    />
                                    <AccessRoute
                                        path="/app/organizer/calendar"
                                        roles={[AvailableRoles.CALENDAR_LIST]}
                                        component={ lazy(() => import('./views/calendar/CalendarView')) }
                                    />
                                    <Route
                                        path="/app/calendar"
                                        component={ lazy(() => import('./views/calendar/CalendarView')) }
                                    />
                                    <Route
                                        path="/app/payments/list"
                                        roles={[AvailableRoles.PAYMENTS]}
                                        component={ lazy(() => import('./views/payments/PaymentsListView')) }
                                    />
                                    {/*<Route
                                        path="/app/management/tenders/documentTemplates/attached/list"
                                        component={ lazy(() => import('./views/management/TenderDocuments/AttachedDocumentsListView')) }
                                    />
                                    <Route
                                        path={ [
                                            "/app/management/tenders/documentTemplates/attached/new",
                                            "/app/management/tenders/documentTemplates/attached/:id/:edit",
                                            "/app/management/tenders/documentTemplates/attached/:id"] }
                                        component={ lazy(() => import('./views/management/TenderDocuments/AttachedDocumentsCreateEditView')) }
                                    />
                                    <Route
                                        path="/app/management/tenders/documentTemplates/requested/list"
                                        component={ lazy(() => import('./views/management/TenderDocuments/RequestedDocumentsListView')) }
                                    />
                                    <Route
                                        path={ [
                                            "/app/management/tenders/documentTemplates/requested/new",
                                            "/app/management/tenders/documentTemplates/requested/:id/:edit",
                                            "/app/management/tenders/documentTemplates/requested/:id"] }
                                        component={ lazy(() => import('./views/management/TenderDocuments/RequestedDocumentsCreateEditView')) }
                                    />*/}
                                    <Route
                                        path="/app/management/orderFields"
                                        component={ lazy(() => import('./views/management/DisplayedOrderFieldsView')) }
                                    />
                                    <Route component={ NotImplemented }/>
                                </Switch>
                                {/*</Suspense>*/ }
                            </DashboardLayout>
                        ) }
                    />
                    <Route component={ Error404View }/>
                </Switch>
            </Suspense>
        </Auth>
    );
}

export default Routes;
