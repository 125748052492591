import React, {
  useEffect,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import SplashScreen from './SplashScreen';
import authService from '../services/authService';
import { logout, setUserData } from '../actions/accountActions';
import User from '../types/User';

interface AuthProps {
  children: React.ReactNode;
}

export default function Auth(
  {
    children
  }: AuthProps) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout())
      });

      //authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        try {
          const isVerified: Boolean = await authService.loginInWithToken();
          const user: User = await authService.getCurrentUser();

          await dispatch(setUserData({
            ...user,
            isVerified: Boolean(isVerified)
          }));
        } catch (e) {
          await dispatch(logout());
        }
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return null;
  }

  return (<>{children}</>);
}
