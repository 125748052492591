import axios from '../utils/axios';
import { API } from '../constants/constants';
import {AvailableEmployeesData, Order} from "../types/Orders";

class NotificationService {

  getNotifications = () => new Promise<any>((resolve, reject) => {
    axios.get(`${ API }/Message/Admin`)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  readMessage = (id: number) => new Promise<boolean>((resolve, reject)=> {
    axios.post(`${ API }/Message/${id}/Read`)
      .then((response) => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });

  sendNotification = (data: any) => new Promise<boolean>((resolve, reject)=> {
    axios.post(`${ API }/Notification/Send`, data)
      .then((response) => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });

  getTemplates = () => new Promise<any>((resolve, reject) => {
    axios.get(`${ API }/Notification/Templates`)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  deleteTemplate = (id: number) => new Promise<any>((resolve, reject) => {
    axios.delete(`${ API }/Notification/Template/${id}`)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  getHistory = () => new Promise<any>((resolve, reject) => {
    axios.get(`${ API }/Notification/History`)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

}

const orderService = new NotificationService();

export default orderService;
