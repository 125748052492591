import { Dispatch } from 'redux';

export const TRIGGER_LOADER = 'TRIGGER_LOADER';

export function triggerLoader(showLoader: boolean) {
  return (dispatch: Dispatch) => dispatch({
    type: TRIGGER_LOADER,
    payload: {
      showLoader
    }
  });
}
