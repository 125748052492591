/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  SET_NOTIFICATIONS,
  UPDATE_PROFILE,
  VERIFIED_USER,
  USER_PHOTO_UPLOADED,
} from '../actions/accountActions';
import { DynamicObject } from '../types/DynamicObject';
import { UpdateUserProps } from '../types/User';

interface Action {
  type: string;
  payload: DynamicObject;
}

interface State {
  user: {} | null;
  notifications: any[] | null;
}

const initialState: State = {
  user: null,
  notifications: []
};

const accountReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
      });
    }

    case LOGIN_SUCCESS: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case SET_NOTIFICATIONS: {
      const notifications: any = action.payload;

      return produce(state, (draft) => {
        draft.notifications = notifications;
      });
    }

    case VERIFIED_USER: {
      const { isVerified } = action.payload;

      return produce(state, (draft) => {
        draft.user = {
          ...draft.user,
          isVerified
        };
      });
    }

    case USER_PHOTO_UPLOADED: {
      const { url } = action.payload;

      return produce(state, (draft) => {
        draft.user = {
          ...draft.user,
          photoUrl: url,
        };
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case SILENT_LOGIN: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case UPDATE_PROFILE: {
      const user = action.payload.data as UpdateUserProps;

      return produce(state, (draft) => {
        draft.user = {
          ...draft.user,
          ...user,
        };
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
