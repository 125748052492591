import produce from 'immer';
import { TRIGGER_LOADER } from '../actions/utilsActions';

interface Action {
  type: string;
  payload: State;
}

interface State {
  showLoader: boolean;
}

const initialState: State = {
  showLoader: false
};

const utilsReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case TRIGGER_LOADER: {
      const { showLoader } = action.payload;

      return produce(state, (draft) => {
        draft.showLoader = showLoader;
      });
    }

    default: {
      return state;
    }
  }
};

export default utilsReducer;
