import React, {useEffect} from 'react';
import {getMobileOperatingSystem} from "../../utils/helper";

function GetAppView() {
    useEffect(() => {
        const os = getMobileOperatingSystem();
        if (os === "iOS") {
            window.location.href = "https://apps.apple.com/ua/app/%D0%B6%D0%B5%D0%BE/id1577958625"
        } else if (os === "Android") {
            window.location.href = "https://play.google.com/store/apps/details?id=com.zheo"
        } else {
            window.history.back()
        }
    }, []);

    return (
        <></>
    );
}

export default GetAppView;
