import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import utilsReducer from './utilsReducer';
import { intlReducer } from 'react-intl-redux'

const rootReducer = combineReducers({
  account: accountReducer,
  form: formReducer,
  intl: intlReducer,
  utils: utilsReducer,
});

export default rootReducer;
