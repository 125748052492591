import React from 'react';

interface LogoProps {
  className?: string;
}

function Logo(props: LogoProps) {
  return (
    <img
      alt="Logo"
      style={{width: '100%'}}
      src="/static/images/logo-text.png"
      {...props}
    />
  );
}

export default Logo;
