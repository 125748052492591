/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Hidden,
    Link,
    List,
    ListSubheader,
    makeStyles,
    Typography
} from '@material-ui/core';
import { Calendar, Folder, Info, List as ListIcon, Plus, Users, CreditCard, Gift } from 'react-feather';
import Logo from '../../../components/Logo';
import NavItem from './NavItem';
import AccountObj from '../../../types/Account';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { messageExists, translate } from '../../../utils/i18nHelpers';
import { NavConfig, NavConfigItems } from '../../../types/NavBarItem';
import { AvailableRoles } from '../../../constants/enums';
import { hasUserAccess } from '../../../utils/helper';
import NotesIcon from '@material-ui/icons/Notes';
import BallotIcon from '@material-ui/icons/Ballot';
import MapIcon from '@material-ui/icons/Map';

const navConfig: NavConfig[] = [
    {
        subHeaderKey: 'Orders',
        roles: [AvailableRoles.ORDER_CREATE, AvailableRoles.ORDER_LIST],
        items: [
            {
                title: 'CreateOrder',
                href: '/app/order/create',
                icon: Plus,
                roles: [AvailableRoles.ORDER_CREATE]
            },
            {
                title: 'OrdersList',
                href: '/app/orders/list',
                icon: ListIcon,
                roles: [AvailableRoles.ORDER_LIST]
            }
          ]
    },
    {
        subHeaderKey: 'Management',
        items: [
            {
                title: 'Users',
                icon: Users,
                href: '/app/management/users',
                roles: [AvailableRoles.USER_LIST, AvailableRoles.ROLE_LIST],
                items: [
                    // {
                    //     title: 'Customers',
                    //     href: '/app/management/customers/list',
                    //     roles: [AvailableRoles.USER_LIST],
                    // },
                    {
                        title: 'UserList',
                        href: '/app/management/user/list',
                        roles: [AvailableRoles.USER_LIST],
                    },
                    // {
                    //     title: 'Employees',
                    //     href: '/app/management/employees/list',
                    //     roles: [AvailableRoles.USER_LIST],
                    // },
                    {
                        title: 'Administrators',
                        href: '/app/management/administrators/list',
                        roles: [AvailableRoles.USER_LIST],
                    },
                    {
                        title: 'ListRoles',
                        href: '/app/management/roles/list',
                        roles: [AvailableRoles.ROLE_LIST],
                    },
                    {
                        title: 'Access',
                        href: '/app/management/access',
                        roles: [AvailableRoles.ROLE_LIST],
                    },
                ]
            },
            {
                title: 'Бригади',
                icon: Users,
                href: '/app/management/teams/list',
                roles: [AvailableRoles.TEAM_LIST],
            },
            {
                title: 'Акції',
                icon: Gift,
                href: '/app/management/promotions/list',
                roles: [],
            },
            {
                title: 'Services',
                icon: Folder,
                href: '/app/management/suppliers',
                roles: [AvailableRoles.CATEGORY_LIST, AvailableRoles.SERVICE_LIST],
                items: [
                    {
                        title: 'CategoriesList',
                        href: '/app/categories/list',
                        roles: [AvailableRoles.CATEGORY_LIST]
                    },
                    {
                        title: 'CategoriesList_1c',
                        href: '/app/categories1c/list',
                        roles: [AvailableRoles.CATEGORY_LIST]
                    },
                    {
                        title: 'ServicesList',
                        href: '/app/services/list',
                        roles: [AvailableRoles.SERVICE_LIST]
                    }
                ]
            },
            {
                title: 'Calendar',
                href: '/app/calendar',
                icon: Calendar,
                roles: [AvailableRoles.CALENDAR_LIST]
            },
            {
                title: 'Payments',
                href: '/app/payments/list',
                icon: CreditCard,
                roles: [AvailableRoles.PAYMENTS]
            },
            {
                title: 'Reporting',
                href: '/app/reporting',
                icon: NotesIcon,
                roles: [AvailableRoles.REPORT_LIST],
                items: [
                    {
                        title: 'Employees',
                        href: '/app/reporting/employees',
                        roles: [AvailableRoles.REPORT_MASTER_VIEW],
                    },
                    {
                        title: 'RegistrationByAddress',
                        href: '/app/reporting/registrations',
                        roles: [AvailableRoles.REPORT_ADDRESS_REGISTRATION_VIEW],
                    },
                    {
                        title: 'WorkingDay',
                        href: '/app/reporting/working_day',
                        roles: [AvailableRoles.REPORT_MASTER_WORKING_DAY_VIEW],
                    },
                    {
                        title: 'EmployeeTimeControl',
                        href: '/app/reporting/time_control',
                        roles: [AvailableRoles.REPORT_MASTER_TIME_CONTROL_VIEW],
                    },
                    {
                        title: 'WorkEvaluationReport',
                        href: '/app/reporting/work_evaluation',
                        roles: [AvailableRoles.REPORT_EVALUATION_GENERAL_VIEW],
                    },
                    {
                        title: 'WorkEvaluationEmployee',
                        href: '/app/reporting/work_evaluation_employee',
                        roles: [AvailableRoles.REPORT_EVALUATION_MASTER_VIEW],
                    },
                    {
                        title: 'WorkEvaluationCustomer',
                        href: '/app/reporting/work_evaluation_customer',
                        roles: [AvailableRoles.REPORT_EVALUATION_USER_VIEW],
                    }
                ]
            },
            {
                title: 'Questionnaire',
                href: '/app/questionnaire',
                icon: BallotIcon,
                roles: [AvailableRoles.QUESTIONNAIRE_LIST],
                items: [
                    {
                        title: 'AllQuestionnaire',
                        href: '/app/questionnaire/list',
                        roles: [AvailableRoles.QUESTIONNAIRE_LIST]
                    },
                    {
                        title: 'QuestionnaireResults',
                        href: '/app/questionnaire/results',
                    }
                ]
            },
            {
                title: 'Map',
                href: '/app/map/general',
                icon: MapIcon,
                roles: [AvailableRoles.MAP_LIST],
            },
            {
                title: 'CustomerInforming',
                icon: Info,
                href: '/app/customer_informing',
                roles: [AvailableRoles.CUSTOMER_INFORMING_LIST, AvailableRoles.CUSTOMER_INFORMING_CREATE],
                items: [
                    {
                        title: 'NewMessage',
                        href: '/app/customer_informing/new',
                        roles: [AvailableRoles.CUSTOMER_INFORMING_CREATE],
                    },
                    {
                        title: 'History',
                        href: '/app/customer_informing/history',
                        roles: [AvailableRoles.CUSTOMER_INFORMING_LIST],
                    }
                ]
            },
        ]
    }
];

//TODO:remove any
function renderNavItems({ items, ...rest }: any) {

    return (
        <List disablePadding>
            { items && items.reduce(
                (acc: any[], item: NavConfigItems) => reduceChildRoutes({ acc, item, ...rest }),
                []
            ) }
        </List>
    );
}

//TODO:remove any
function reduceChildRoutes({
                               acc,
                               pathname,
                               item,
                               depth = 0
                           }: any) {
    const key = item.title + depth;

    if (!hasUserAccess(item.roles)) {
        return acc;
    }

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false
        });

        acc.push(
            <NavItem
                depth={ depth }
                icon={ item.icon }
                key={ key }
                info={ item.info }
                open={ Boolean(open) }
                title={ item.title }
            >
                { renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items
                }) }
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={ depth }
                href={ item.href }
                icon={ item.icon }
                key={ key }
                info={ item.info }
                title={ item.title }
            />
        );
    }

    return acc;
}

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 270,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64,
    },
    headerTitle: {}
}));

interface NavBarProps {
    onMobileClose: () => void;
    openMobile: boolean;
}

function NavBar({ openMobile, onMobileClose, ...props }: NavBarProps & WrappedComponentProps) {
    const classes = useStyles();
    const location = useLocation();
    const { user } = useSelector((state: AccountObj) => state.account);
    const photoUrl = `${ user.photoUrl }?${ Date.now() }`;
    const [ready, setReady] = useState(true);
    const userName = user.firstName
        ? `${ user.firstName } ${ user.lastName }`
        : user.email;

    /*const getSAPList = useCallback(async () => {
        const sapList = navConfig.find((item: NavConfig) => {
            return item.subHeaderKey === 'SAP';
        });

        if (sapList) {
            const directories = sapList.items.find((item: NavConfigItems) => {
                return item.title === 'Directories';
            });

            const list = await navBarHelper.getNavBarListByType('SAP');
            if (directories) {
                directories.items = list;
            }
            setReady(true);
        }
    }, [setReady]);

    useEffect(() => {
        getSAPList();
    }, [getSAPList]);*/

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    const getTranslations = (config: NavConfig) => {
        const id = `app:NavBar;Item;header;${ config.subHeaderKey };title`;
        const isExist = messageExists(props, id);
        if (isExist) {
            return translate(props, id, config.subHeaderKey);
        } else {
            return config.subHeaderKey;
        }
    };

    if (!ready) {
        return null;
    }
    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <PerfectScrollbar options={ { suppressScrollX: true } }>
                <Hidden lgUp>
                    <Box
                        p={ 2 }
                        display="flex"
                        justifyContent="center"
                    >
                        <RouterLink to="/">
                            <Logo/>
                        </RouterLink>
                    </Box>
                </Hidden>
                <Box p={ 2 }>
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <Link
                            component={ RouterLink }
                            to="/app/account"
                            underline="none"
                        >
                            <Avatar
                                alt="User"
                                className={ classes.avatar }
                                src={ photoUrl }
                            />
                        </Link>
                    </Box>
                    <Box
                        mt={ 2 }
                        textAlign="center"
                    >
                        <Link
                            component={ RouterLink }
                            to="/app/account"
                            variant="h5"
                            color="textPrimary"
                            underline="none"
                        >
                            { userName }
                        </Link>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                        >
                            { typeof user.role !== 'string' ? user.role && user.role.displayName && user.role.displayName.ua : user.role }
                        </Typography>
                    </Box>
                </Box>
                <Divider/>
                <Box p={ 2 }>
                    { navConfig.map((config) => {
                        if (!hasUserAccess(config.roles)) {
                            return null;
                        }
                        return (
                            <List
                                key={ config.subHeaderKey }
                                subheader={ (
                                    <ListSubheader
                                        disableGutters
                                        disableSticky
                                        className={ classes.headerTitle }
                                    >
                                        { getTranslations(config) }
                                    </ListSubheader>
                                ) }
                            >
                                { renderNavItems({
                                    items: config.items,
                                    pathname: location.pathname
                                }) }
                            </List>
                        );
                    }) }
                </Box>
                <Divider/>
            </PerfectScrollbar>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={ { paper: classes.mobileDrawer } }
                    onClose={ onMobileClose }
                    open={ openMobile }
                    variant="temporary"
                >
                    { content }
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={ { paper: classes.desktopDrawer } }
                    open
                    variant="persistent"
                >
                    { content }
                </Drawer>
            </Hidden>
        </>
    );
}

export default injectIntl(NavBar);

