import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon, Typography
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import { THEMES } from '../../../constants/enums';
import Account from './Account';
// import Contacts from './Contacts';
// import Notifications from './Notifications';
// import Search from './Search';
import Settings from './Settings';
import { ThemConfig } from '../../../types/Them';
import { FormattedMessage } from 'react-intl';
import Notifications from './Notifications';

const useStyles = makeStyles((theme: ThemConfig) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    minHeight: 64
  },
  menuButton:{},
  logoLink: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    textDecoration: 'none'
  },
  text: {
    lineHeight: 1.3,
  },
  logo: {
    maxHeight: '45px',
  },
  centerTitle: {
    flexGrow: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
  }
}));

interface TopBarProps {
  className?: string;
  onMobileNavOpen: ()=>void;
}

export default function TopBar({
  className,
  onMobileNavOpen,
  ...rest
}: TopBarProps) {
  const classes = useStyles();
  const today = new Date();
  const date = `${today.getDate()}/${today.getMonth()+1}/${today.getFullYear()}`;

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink className={ classes.logoLink } to="/">
            <img
                alt="Logo"
                className={ classes.logo }
                src="/static/images/logo-text.png"
            />
          </RouterLink>
        </Hidden>
        <Hidden mdDown>
          <div className={classes.centerTitle} >
            <Typography
                variant="subtitle2">
              <FormattedMessage id='app:TopBar;Logo:title2'
                                defaultMessage='ЖЕО'/>
            </Typography>
          </div>
        </Hidden>
        {/*<Search />*/}
        {/*<Contacts />*/}
        <Notifications />
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
