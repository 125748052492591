import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import User from '../types/User';

interface GuestRouteProps {
  component: any,
  render?: any,
  exact?: any,
  path?: any,
}

interface State {
  account: {
    user: User,
  },
}

function GuestRoute({ component: Component, render, ...rest }:GuestRouteProps) {
  const account = useSelector((state:State) => state.account);

  if (account.user) {
    return <Redirect to="/app" />;
  }

  return render ? render({ ...rest }) : <Component {...rest} />;
}
export default GuestRoute;
