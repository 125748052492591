import React, {
  forwardRef,
  useEffect,
  useCallback,
} from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import track from '../utils/analytics';

export interface PageProps {
  title?: string;
  header?: React.ReactNode;
  children?: React.ReactNode;
  hasBackground?: boolean;
  hasOverlap?: boolean;
  hasOverlapMajor?: boolean;
  hasShortHeader?: boolean;
  className?: string;
  styles?: {
    header?: { [key: string]: string };
    layout?: { [key: string]: string };
  };
}

const Page = forwardRef(({
  title,
  children,
  ...rest
}: PageProps, ref: React.Ref<HTMLDivElement>) => {
  const location = useLocation();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: location.pathname
    });
  }, [location]);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <div
      {...rest}
      ref={ref}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
});

export default Page;
